import React, { useState, useEffect } from "react";
import MessageBox from "../../components/Alert/MessageBox";
import NavBar from "../../components/navBar/navBar";
import { __API_URL__ } from "../../SERVER_URL";
import { useParams } from "react-router";
import useUser from "../../hooks/user";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AddNewPatientModal from "../../components/Modals/AddNewPatient";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import PopMessage from "../../components/PopUp/PopMessage";
import TrialNotification from "../Patient/TrialNotification";
import BasicInfoAccordion from "../../components/TrialAccordions/BasicInfoAccordion";
import VisitTabAccordion from "../../components/TrialAccordions/VisitTabAccordion";
import TrialNotificationAccordion from "../../components/TrialAccordions/TrialNotificationAccordion";
import SurveyPoem from "../../components/Survey/SurveyPoem";
import AllDrugKits from "../Drug/AllDrugKits";
import axios from "axios";

const TrialDetails = () => {
    const [message, setMessage] = useState("");
    const [typeOfMessage, setTypeOfMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const { _id_ } = useParams();
    const token = localStorage.getItem("token");const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const user = useUser();
    const [checkPatient, setCheckPatient] = useState(false)
    const [trialDetails, setTrialDetails] = useState({})
    const now = moment(); 
    const today = now; 
    const [dateTime, changeDateTime] = useState(today);
    let navigate = useNavigate();
    const [trailsList, setTrialsList] = useState([]);
    const [allSurveys, setAllSurveys] = React.useState([])
    const [patientData, setPatientData] = useState({
        email: "",
        first_name: "",
        last_name: "",
        custom_id: 0,
      });

    const defaultVisit = {
        survey_form: '', drug_video: false, extra_photos: [], visit_schedule: 0,
        visit_number: 0,
        consent_form: {
            content: "", title: "", id:''
        },
        consent_list_id: "",
        trial_block:[],
        is_all_instruction:false,
        end_visit_schedule:0,
        visit_name: ""
    }
    const [modalShow, setModalShow] = useState(false);
    const [modalPatientShow, setModalPatientShow] = useState(false);
    const [modalVisitChangeShow, setModalVisitChangeShow] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(defaultVisit)
    const [numberOfVisits, setNumberOfVisits] = useState(1)
    const [numberOfVisitsArray, setNumberOfVisitsArray] = useState([])
    const [hasChanged, setHasChanged] = useState(false)
    const [eventList, setEventList] = React.useState([])
    const [zeroVisit, setZeroVisit] = React.useState(false)
    const [surveyFormBool, setSurveyFormBool] = React.useState(false)
    const [allVisits, setAllVisits] = React.useState([])
    const [value, setValue] = React.useState(0);
    const [consentFormBool, setConsentFormBool] = React.useState(false)
    const [isEvent, setisEvent] = useState({ adverse_event: false, unscheduled_event:false});
    const [selectedSessionId, setSelectedSessionId] = useState(0);
    const [addNewSurvey, setAddNewSurvey] = useState(false);
    const [viewDrug, setViewDrug] = useState(false);
    const [drugKits, setDrugKits] = useState([])
    const [searchDrug, setSearchDrug] = useState('')
    const [page, setPage] = useState(1);
    // in case of discarding the change
    const [visitToSelect, setVisitToSelect] = useState({});

    const handleVisitFieldChange = (e) => {
        const { name, value } = e.target;

        if (name === 'drug_video') {
            setSelectedVisit((visit) => {
                return {
                    ... visit,
                    [name]: !visit.drug_video
                }
            })
        } else if (["Lesion 1", "Lesion 2", "Lesion 3"].includes(name)) {
            
            setSelectedVisit((visit) => {
                let current_extras = visit.extra_photos
                const index = current_extras.indexOf(name);
                if (index > -1) current_extras.splice(index, 1);
                else current_extras.push(name);
                return {
                    ... visit,
                    extra_photos: current_extras
                }
            })
        } else if (name === "title" || name === "content") {
            setSelectedVisit((visit) => {
                return {
                    ... visit,
                    consent_form: {
                        ... visit.consent_form,
                        [name]: value
                    }
                }
            })
        } else if (name === "consent_list_id") {
            setSelectedVisit((visit) => {
                return {
                    ... visit,
                    consent_form: {
                        ... visit.consent_form,
                        id: value
                    }
                }
            })
        }
        else {
            setSelectedVisit((visit) => {
                return {
                    ... visit,
                    [name]: value
                }
            })
        }
    }

    const AllSurveyData = () => {
        setLoader(true);
        axios
            .get(`${__API_URL__}/api/survey/survey_trial`, {
            headers: {
                Authorization: `Token ${token}`,
            },
            })
            .then((res) => {
                setAllSurveys(res.data);
                setLoader(false);
            }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
                user.actions.logout()
                }
            });
        };

    const addPatientApi = () => {
        const Createdata = {
        ...patientData,
          'trail':_id_, 
          'start_date': dateTime,
          'checkPatient': checkPatient,
        };
        axios
        .post(`${__API_URL__}/api/patient/patient/add_patient/`, Createdata, {
            headers: {
            Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            res = res.data
            setPatientData(res);
            setCheckPatient(false);
            setModalPatientShow(false)
            setMessage("Patient successfully added");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
            setTimeout(() => {
              navigate(`/patient/${res.id}/ecrf/`)
            }, 2000);
        }).catch((err) => {
            // setCheckPatient(true); 
            // setModalShow(false)
            setMessage(err.response.data.message);
            setTypeOfMessage("error");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 5000);
        });
    }

    const AllVisitsData = (inital_flag) => {
    axios
      .get(`${__API_URL__}/api/photos/get_visits/${_id_}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllVisits(res.data);
        setLoader(false)
        const screeningVisitSelected = res.data.filter((visit) => {
            return visit.visit_number == -3
        })
        const zeroVisitSelected = res.data.filter((visit) => {
            return visit.visit_number == 0
        })
        setZeroVisit(zeroVisitSelected[0])
        if (inital_flag) {
            if (res.data.length > 0) {
                let filteredData = res.data.filter(item => item.visit_number === -1 || item.visit_number === -2 || item.visit_number === -3 || item.visit_number === -4);
                let visitNumbers = filteredData.map(item => item.visit_number);
                setEventList(visitNumbers);
                let newNumberOfVisits = res.data.length - (filteredData.length+1);
                if (newNumberOfVisits < 0) newNumberOfVisits = 0
                setNumberOfVisits(newNumberOfVisits);
                if (screeningVisitSelected.length > 0) {
                    handleSelectingVisit(screeningVisitSelected[0])
                    setSelectedVisit({...screeningVisitSelected[0], start_date: new Date(screeningVisitSelected[0].start_date + "Z")})
                }
                else if (zeroVisitSelected.length > 0) {
                    handleSelectingVisit(zeroVisitSelected[0])
                    setSelectedVisit({...zeroVisitSelected[0], start_date: new Date(zeroVisitSelected[0].start_date + "Z")})
                }
            } else {
                setHasChanged(true)
            }
        } else {
            const concernedVisitSelected = res.data.filter((visit) => {
                return visit.visit_number == selectedVisit.visit_number
            })
            if (concernedVisitSelected.length > 0) {
                setSelectedVisit({...concernedVisitSelected[0], start_date: new Date(concernedVisitSelected[0].start_date + "Z")})
            }
        }
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  };

  const trialDetailApi = () => {
    axios
        .get(`${__API_URL__}/api/photos/get_trial/${_id_}/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            res = res.data
            setTrialDetails(res);

        });
}

const GetDrugKitDropDown = () => {
    axios
      .get(`${__API_URL__}/api/dropdown_drugs/?page=${page}&trial=${_id_}&name=${searchDrug}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setDrugKits((prevDrugKits) => res.data?.results.slice(0, 5));
      });
  }
  
  const handleSelectingVisit = (zeroVisit) => {
    if (zeroVisit.consent_form.id) {
        setConsentFormBool(true)
    } else setConsentFormBool(false)
    if (zeroVisit.survey_form.id) {
        setSurveyFormBool(true) 
    } else setSurveyFormBool(false)
}
React.useEffect(()=>{
    trialDetailApi()
    AllSurveyData()
},[])

useEffect (()=>{
    if (trialDetails?.manual_drug){
        GetDrugKitDropDown()
    }
},[trialDetails.manual_drug])

useEffect(() => {
    const delay = 1000; 
    const timer = setTimeout(() => {
        GetDrugKitDropDown();
    }, delay);
    return () => clearTimeout(timer);
}, [searchDrug]);


React.useEffect(() => {
    if (eventList.includes(-1)) {
        setisEvent(prevState => ({
            ...prevState,
            adverse_event: true,
        }));
    }
    if (eventList.includes(-2)) {
        setisEvent(prevState => ({
            ...prevState,
            unscheduled_event: true,
        }));
    }
    if (eventList.includes(-3)) {
        setisEvent(prevState => ({
            ...prevState,
            screening_visit: true,
        }));
    }
}, [eventList]);

useEffect(() => {
    if (addNewSurvey) {
      const loaderTimeout = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => {
        clearTimeout(loaderTimeout);
      };
    }
  }, [addNewSurvey]);

    return (
        <>
          <MessageBox msg={message} type={typeOfMessage} />
          <NavBar active="trials" />
            {
            viewDrug? 
            <AllDrugKits
            setMessage={setMessage}
            setTypeOfMessage={setTypeOfMessage}
            trial={_id_}
            manual_drug={trialDetails.manual_drug}
            unassign_drug_count={trialDetails.unassign_drug_count}
            trialDetailApi={trialDetailApi}
            />
            
          :<div className="container-fluid">
            <div className="patient_table info_form" style={{boxShadow: "0 0 0 "}}>
                <div className="tableHeader">
                    <div className="row">
                        <div className="col-md-6">
                        <h5>Update Trial</h5>
                        </div>

                        <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-2 mx-auto">
                                {((role === "Investigator" || role === 'Sub_investigator' || role === "Inv_coordinator") && trialDetails?.drug_assignment) && (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm float-end"
                                    style={{ width: "120px" }}
                                    onClick={() => setViewDrug(true)}
                                >
                                    View Drugs
                                </button>
                                )}
                            </div>

                            <div className="col-md-1">
                                {(role === "Investigator" || role === 'Sub_investigator' || role === "Inv_coordinator") && (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm float-end"
                                    style={{ width: "120px" }}
                                    onClick={() => setModalPatientShow(true)}
                                >
                                {user_data.role === 'Investigator' || user_data.role === 'Inv_coordinator' ? "Add Subject" : "Add Patient"}
                                </button>
                                )}
                            </div>
                            </div>

                    </div>
                </div>
                <BasicInfoAccordion 
                    numberOfVisits={numberOfVisits} 
                    numberOfVisitsSetter={setNumberOfVisits} 
                    setMessage={setMessage}
                    setTypeOfMessage={setTypeOfMessage}
                    isEvent={isEvent}
                    setisEvent={setisEvent}
                    eventList={eventList}
                    setEventList={setEventList}
                    
                />
                <VisitTabAccordion 
                    numberOfVisits={numberOfVisits}
                    isEvent={isEvent}
                    zeroVisit={zeroVisit}
                    value={value}
                    setValue={setValue}
                    allVisits={allVisits}
                    numberOfVisitsArray={numberOfVisitsArray}
                    setNumberOfVisitsArray={setNumberOfVisitsArray}
                    surveyFormBool={surveyFormBool}
                    setSurveyFormBool={setSurveyFormBool}
                    consentFormBool={consentFormBool}
                    setConsentFormBool={setConsentFormBool}
                    handleSelectingVisit={handleSelectingVisit}
                    eventList={eventList}
                    selectedVisit={selectedVisit}
                    setSelectedVisit={setSelectedVisit}
                    AllVisitsData={AllVisitsData}
                    setNumberOfVisits={setNumberOfVisits}
                    setMessage={setMessage}
                    setTypeOfMessage={setTypeOfMessage}
                    trialId={_id_}
                    setModalVisitChangeShow={setModalVisitChangeShow}
                    hasChanged={hasChanged}
                    setHasChanged={setHasChanged}
                    selectedSessionId={selectedSessionId}
                    setSelectedSessionId={setSelectedSessionId}
                    setAddNewSurvey={setAddNewSurvey}
                    setLoader={setLoader}
                    loader={loader}
                    allSurveys={allSurveys}
                    trialDetails={trialDetails}
                    setVisitToSelect={setVisitToSelect}
                    visitToSelect={visitToSelect}
                />
                <TrialNotificationAccordion 
                    trialId={_id_}
                    TrialNotification={TrialNotification}      
                    setMessage={setMessage}
                    setTypeOfMessage={setTypeOfMessage}
                />
            </div>
          </div>
        }
          {modalShow && (
                <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered show onHide={() => setModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add/Update Consent
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <div>
                            <label>Title</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => handleVisitFieldChange(e)}
                                name="title"
                                value={selectedVisit.consent_form.title}
                            ></input>
                            <label>Content</label>
                            <textarea
                                type="text"
                                className="form-control"
                                onChange={(e) => handleVisitFieldChange(e)}
                                name="content"
                                value={selectedVisit.consent_form.content}
                            ></textarea>
                        </div>
                    </Modal.Body>
                </Modal>
          )}
          {modalPatientShow && (
        <>
          <AddNewPatientModal
            trailsList={trailsList}
            show={modalPatientShow}
            modalShow={setModalPatientShow}
            onHide={() => setModalShow(false)}
            setCheckPatient={setCheckPatient}
            changeDateTime={changeDateTime}
            dateTime={dateTime}
            today={today}
            addPatientApi={addPatientApi}
            patientData={patientData}
            setPatientData={setPatientData}
            is_manual={trialDetails?.is_manual}
            is_setting={trialDetails?.is_setting}
            manual_drug={trialDetails?.manual_drug}
            drugKits={drugKits}
            GetDrugKitDropDown={GetDrugKitDropDown}
            setSearchDrug={setSearchDrug}
            trialHasScreening={isEvent.screening_visit}
          />
        </>
      )}
      {
          checkPatient &&
               <PopMessage
                  setCheckPatient={setCheckPatient}
                  addPatientApi={addPatientApi}
               ></PopMessage>
              }
          {
            modalVisitChangeShow && <>
                <Modal size="md" show onHide={() => setModalVisitChangeShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Unsaved Changes
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <p className="text-center">You have Unsaved changes for the current opened Visit!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => {setHasChanged(false); setModalVisitChangeShow(false)}}>Discard Changes</Button>
                        <Button variant="primary" onClick={() => setModalVisitChangeShow(false)}>Continue Making Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
          }
            {addNewSurvey && (
            <Modal size="xl" show={addNewSurvey} onHide={() => setAddNewSurvey(false)} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <SurveyPoem setAddNewSurvey={setAddNewSurvey} AllSurveyData={AllSurveyData} trial_detail={true} />
            </Modal>
            )}
        </>
    )
}
export default TrialDetails;