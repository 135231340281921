import React from "react";
import MainAccordion from "../Accordion/accordion";

const VisitNameAccordion = ({
  role,
  handleVisitFieldChange,
  selectedVisit,
}) => {
  return (
    <MainAccordion
      title="Visit Name"
      isOpen={true}
      data={
        <>
          <div
            className="col-md-3 mx-2"
            style={{
              textAlign: "left",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <input
              type="text"
              className="form-control"
              onChange={(e) => handleVisitFieldChange(e)}
              name="visit_name"
              value={selectedVisit.visit_name?.trim() ? selectedVisit.visit_name : ""}
              disabled={role !== "CRO Admin" && role !== "Cro_coordinator" && role !== "Data Manager"}
            ></input>
          </div>
        </>
      }
    />
  );
};
export default VisitNameAccordion;
