import React, { useState, useRef, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { CheckCircle } from '@mui/icons-material';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Box, Typography, Tabs, Tab, Select, MenuItem } from '@mui/material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsWithDropdown = ({
  numberOfVisitsArray,
  allVisits,
  patientId,
  handleTabChange,
  value,
  selectedVisit,
  setSelectedVisit,
}) => {
  const [dropdownValue, setDropdownValue] = useState('');
  const [visitsDisabled, setVisitsDisabled] = useState(false);
  const [hasIncompleted, setHasIncompleted] = useState(false);
  const [newVisits, setNewVisits] = useState([0]);
  const [currentVisit, setCurrentVisit] = useState({});
  const visitTabs = useRef({ 0: 0 });
  const visitMenus = useRef({});

  const VISIT_NAMES = {
    0: 'Baseline Visit',
    '-1': 'Adverse Event',
    '-2': 'Unscheduled Visit',
    '-3': 'Screening Visit',
    '-4': 'OTHER ECRFS',
  };

  useEffect(() => {
    visitTabs.current = { 0: 0 };

    const sortedNumberOfVisitArrays = numberOfVisitsArray.sort().reverse();
    sortedNumberOfVisitArrays.forEach((number) => {
      if (number < 0 && number !== -3) {
        const key = number.toString();

        if (!(key in visitTabs.current)) {
          visitTabs.current[key] = Object.keys(visitTabs.current).length;
        }
      }
    });
  }, [numberOfVisitsArray, selectedVisit]);

  useEffect(() => {
    numberOfVisitsArray.forEach((visitNumber) => {
      if (visitNumber === selectedVisit.visit_number) {
        let visit = allVisits.filter(
          (visit) => visit.visit_number === visitNumber
        )[0];

        if (!visit) {
          visit = { visit_number: visitNumber, visit_name: '' };
        }

        setCurrentVisit(visit);
      }
    });
  }, [selectedVisit, numberOfVisitsArray]);

  useEffect(() => {
    setHasIncompleted(false);

    allVisits.forEach((visit) => {
      const visitNameOrDefault =
        visit.visit_name.trim() ? visit.visit_name : VISIT_NAMES[visit.visit_number?.toString()];

      if (visit.visit_number === 0 && dropdownValue === '') {
        setDropdownValue((prev) => (prev === '' ? visitNameOrDefault : prev));
      }

      if (visit.visit_number === -3) {
        setDropdownValue(visitNameOrDefault);

        if (selectedVisit.visit_number !== visit.visit_number)
          handleTabChange(0, -3, 0);

        if (patientId) {
          setVisitsDisabled(!visit.is_baseline_scheduled);
        }
      }
    });
  }, [allVisits]);

  useEffect(() => {
    let hasScreeningInAllVisits = false;
    const allVisitNumbers = allVisits.map((visit) => visit.visit_number);
    const missingVisits = numberOfVisitsArray.filter(
      (visitNumber) => !allVisitNumbers.includes(visitNumber)
    );

    let selectedDropdownValue = '';

    if (!patientId) {
      if (allVisitNumbers.includes(-3)) {
        hasScreeningInAllVisits = true;
        selectedDropdownValue =
          currentVisit.visit_name ||
          VISIT_NAMES[currentVisit.visit_number?.toString()];

        handleTabChange(0, -3, 0);
      } else if (allVisitNumbers.includes(0)) {
        selectedDropdownValue =
          currentVisit.visit_name ||
          VISIT_NAMES[currentVisit.visit_number?.toString()];

        handleTabChange(0, 0, 0);
      }

      if (selectedDropdownValue) {
        selectedDropdownValue =
          currentVisit.visit_number >= 0 || currentVisit.visit_number === -3
            ? selectedDropdownValue
            : dropdownValue;

        setDropdownValue(selectedDropdownValue);

        if (currentVisit.visit_number < 0 && currentVisit.visit_number !== -3) {
          if (visitTabs.current[currentVisit.visit_number?.toString()]) {
            handleTabChange(
              0,
              currentVisit.visit_number,
              visitTabs.current[currentVisit.visit_number?.toString()]
            );
          } else {
            setSelectedVisit(
              allVisits.filter((visit) => visit.visit_number === 0)[0]
            );
          }
        }
      } else {
        setDropdownValue(
          currentVisit.visit_number > 0
            ? 'Visit ' + currentVisit.visit_number
            : dropdownValue
        );

        if (currentVisit.visit_number < 0 && currentVisit.visit_number !== -3) {
          handleTabChange(
            0,
            currentVisit.visit_number,
            visitTabs.current[currentVisit.visit_number?.toString()]
          );
        }
      }
    }

    if (!hasScreeningInAllVisits && numberOfVisitsArray.includes(-3)) {
      handleTabChange(0, -3, 0);
      setDropdownValue(VISIT_NAMES['-3']);
    }

    setNewVisits(missingVisits);
  }, [allVisits, numberOfVisitsArray]);

  useEffect(() => {
    if (currentVisit?.visit_number > 0) {
      setDropdownValue(
        currentVisit.visit_name.trim() ? currentVisit.visit_name : 'Visit ' + currentVisit.visit_number
      );
    } else if (
      currentVisit.visit_number === 0 ||
      currentVisit.visit_number === -3
    ) {
      setDropdownValue(
        currentVisit.visit_name.trim() ? currentVisit.visit_name : VISIT_NAMES[currentVisit.visit_number?.toString()]
      );
    }
  }, [currentVisit]);

  const specialUnscheduledCounts = allVisits
    ?.filter((visit) => visit.visit_type === 'Unscheduled Event')
    .map((visit) => visit.special_unscheduled_count);
  const specialAdverseCounts = allVisits
    ?.filter((visit) => visit.visit_type === 'Adverse Event')
    .map((visit) => visit.special_adverse_count);

  const renderLabel = (visitLabel, number, patientId) => {
    let badgeContent = null;

    if (
      number === -2 &&
      patientId !== undefined &&
      specialUnscheduledCounts &&
      specialUnscheduledCounts.length > 0
    ) {
      badgeContent = specialUnscheduledCounts;
    } else if (
      number == -1 &&
      patientId !== undefined &&
      specialAdverseCounts &&
      specialAdverseCounts.length > 0
    ) {
      badgeContent = specialAdverseCounts;
    }

    if (patientId === undefined) {
      return (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            paddingRight: '10px',
          }}
        >
          {visitLabel}
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            paddingRight: '10px',
          }}
        >
          {visitLabel}
          {badgeContent !== null ? (
            <Badge
              badgeContent={badgeContent}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor:
                    visitLabel === 'Unscheduled Event' ? 'red' : 'blue',
                  color: 'white',
                  position: 'absolute',
                  top: '-20px',
                  right: '-25px',
                  transform: 'none',
                  zIndex: 1,
                },
              }}
            />
          ) : (
            <Badge
              badgeContent={0}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'gray',
                  color: 'white',
                  position: 'absolute',
                  top: '-20px',
                  right: '-25px',
                  transform: 'none',
                  zIndex: 1,
                },
              }}
            />
          )}
        </div>
      );
    }
  };

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;

    setDropdownValue(newValue);
  };

  return (
    <Tabs value={value} aria-label="basic tabs example">
      <Tab
        label={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Select
              value={dropdownValue}
              onChange={handleDropdownChange}
              variant="standard"
              disableUnderline
            >
              {allVisits.length > 1 &&
                allVisits.map((visit) => {
                  let isCompleted = true;

                  if (
                    (visit.visit_number >= 0 && !visitsDisabled) ||
                    visit.visit_number === -3
                  ) {
                    for (let block of visit.trial_block) {
                      if (
                        !block.is_completed ||
                        block.possible_incorrect_data ||
                        block.has_constraint_error
                      ) {
                        isCompleted = false;
                        if (!hasIncompleted) setHasIncompleted(true);
                      }
                    }

                    let visitLabel = '';

                    visitLabel = visit.visit_name;

                    if (!visitLabel) {
                      visitLabel =
                        visit.visit_number > 0
                          ? 'Visit ' + visit.visit_number
                          : VISIT_NAMES[visit.visit_number.toString()];
                    }

                    if (
                      !(visit.visit_number.toString() in visitMenus.current)
                    ) {
                      visitMenus.current[visitLabel] = visit.visit_number;
                    }

                    return (
                      <MenuItem
                        key={visit.visit_number}
                        value={visitLabel}
                        onClick={(event) =>
                          handleTabChange(event, visit.visit_number, 0)
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body1">{visitLabel}</Typography>

                          {patientId &&
                            (isCompleted ? (
                              <CheckCircle
                                color="success"
                                sx={{ fontSize: '12px', marginLeft: '8px' }}
                              />
                            ) : (
                              <ClearRoundedIcon
                                color="error"
                                sx={{ fontSize: '12px', marginLeft: '8px' }}
                              />
                            ))}
                        </Box>
                      </MenuItem>
                    );
                  }
                  return null;
                })}

              {!patientId &&
                newVisits.sort((a, b) => a - b).map((number) => {
                  let visitLabel = '';

                  if (number >= 0 || number === -3) {
                    visitLabel =
                      number > 0
                        ? 'Visit ' + number
                        : VISIT_NAMES[number.toString()];

                    if (!(number.toString() in visitMenus.current)) {
                      visitMenus.current[visitLabel] = number;
                    }

                    return (
                      <MenuItem
                        key={number}
                        value={visitLabel}
                        onClick={(event) => handleTabChange(event, number, 0)}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body1">{visitLabel}</Typography>
                        </Box>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Select>

            {patientId && hasIncompleted && (
              <DoNotDisturbAltRoundedIcon
                color="error"
                sx={{
                  position: 'absolute',
                  top: '6px',
                  right: '0',
                  fontSize: '20px',
                }}
              />
            )}
          </Box>
        }
      />

      {numberOfVisitsArray.map((number) => {
        if (number < 0 && number !== -3) {
          let visitLabel = '';

          for (let visit of allVisits) {
            if (visit.visit_number == number) {
              visitLabel = visit.visit_name;

              if (!visitLabel.trim()) {
                visitLabel = VISIT_NAMES[number.toString()];
              }
            }
          }

          if (!patientId && !visitLabel.trim())
            visitLabel = VISIT_NAMES[number.toString()];

          return (
            <Tab
              key={number}
              label={renderLabel(visitLabel, number, patientId)}
              {...a11yProps(number)}
              onClick={(event) =>
                handleTabChange(
                  event,
                  number,
                  visitTabs.current[number.toString()]
                )
              }
            />
          );
        }
        return null;
      })}
    </Tabs>
  );
};

export default TabsWithDropdown;
